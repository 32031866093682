<!--
 * @Description: 维修记录
 * @Author: LiangYiNing
 * @Date: 2021-12-29 10:48:41
 * @LastEditTime: 2022-07-15 17:13:21
 * @LastEditors: ChenXueLin
-->

<template>
  <div v-loading="loading" :element-loading-background="loadingBackground">
    <div class="edit-title">维修次数：{{ total }}</div>
    <section class="table-wrapper">
      <el-table
        border
        height="658px"
        :data="tableData"
        highlight-current-row
        avcsdfjslkjshul
      >
        <el-table-column
          show-overflow-tooltip
          v-for="(column, index) in columnData"
          :key="index"
          :prop="column.fieldName"
          :label="column.fieldLabel"
          :min-width="column.width"
          :fixed="column.fixed"
          :align="column.align"
          header-align="center"
        >
        </el-table-column>
      </el-table>
    </section>
    <!-- 分页 start -->
    <section class="pagination-wrapper fixed-section">
      <el-pagination
        :page-size.sync="searchForm.pageSize"
        :current-page.sync="searchForm.pageIndex"
        :page-sizes="pageSizes"
        :layout="layout"
        :total="total"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </section>
    <!-- 分页 end -->
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import { getRepairRecordList } from "@/api";
export default {
  name: "maintenanceRecord",
  mixins: [base, listPage, listPageReszie],
  data() {
    return {
      searchForm: {
        terminalId: "", //终端设备id
        pageIndex: 1,
        pageSize: 20
      },
      total: 0,
      columnData: [
        {
          fieldName: "workNo",
          display: true,
          fieldLabel: "关联单据",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "workTypeName",
          display: true,
          fieldLabel: "关联单据类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "label",
          display: true,
          fieldLabel: "对接状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "quesName",
          display: true,
          fieldLabel: "问题描述",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "quesReason",
          display: true,
          fieldLabel: "诊断问题",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "solution",
          display: true,
          fieldLabel: "处理方案",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "thirdClassName",
          display: true,
          fieldLabel: "商品名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equipCode",
          display: true,
          fieldLabel: "设备编号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "workSecondClassName",
          display: true,
          fieldLabel: "设备操作",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //已安装设备表头
      tableData: [] // 表格数据
    };
  },
  computed: {},
  components: {},
  watch: {},
  created() {},
  methods: {
    init(terminalId) {
      this.searchForm.terminalId = terminalId;
      if (this.searchForm.terminalId) {
        this.queryList();
      }
    },
    async queryList() {
      try {
        this.loading = true;
        let res = await getRepairRecordList(this.searchForm);
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.change-type-box {
  margin-top: 20px;
  height: 35px;
  display: flex;
  margin-left: 15px;
  box-sizing: border-box;
  .type {
    display: inline-block;
    text-align: center;
    line-height: 35px;
    color: #929395;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
    border-top: 1px solid #dedfe1;
    border-bottom: 1px solid #dedfe1;
    border-right: 1px solid #dedfe1;
    &:nth-of-type(1) {
      border-left: 1px solid #dedfe1;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &:nth-last-of-type(1) {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  .active {
    color: #fff;
    background: #46bfea;
    &:nth-of-type(1) {
      border-right: 1px solid #46bfea;
      border-left: 1px solid #46bfea;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
    &:nth-last-of-type(1) {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}
</style>
